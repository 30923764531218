import AppWrapper from "./AppWrapper";
import KnowledgeBasePage from "./components/knowledgebase/KnowledgeBasePage";

function App({ botId, version, organizationId }: { botId: string; version: string; organizationId: string}) {
  return (
    <AppWrapper botId={botId} version={version} organizationId={organizationId}>
      <KnowledgeBasePage />
    </AppWrapper>
  );
}

export default App;
