import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Entrypoint for running the MFE outside of the host application
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App botId="123" version="DRAFT" />
  </React.StrictMode>
);
